import React from "react";
import Modal from "../../shared/modal";
import styles from "./styles.css";
import closeImage from "./images/close.svg";
import OurLocationCard from "../../shared/our-location-card";
import CheckCarsFromAllStates from "../../shared/check-cars-from-all-states";
import { PropTypes } from "prop-types";
import useCarHighlightLocation from "../../../hooks/use-car-highlight-location";

const OurLocationModal = ({
    handleModalClose
}) => {

    const { getHubsInfo } = useCarHighlightLocation();
    const allHubsInfo = getHubsInfo() || [];

    return (
        <Modal isOpen={true} close={handleModalClose}>
            <div styleName={"styles.modalWrapper"}>
                <div styleName={"styles.headerWrap"}>
                    <p styleName={"styles.heading"}>Our locations</p>
                    <img src={closeImage} alt="close" onClick={handleModalClose}/>
                </div>
                { allHubsInfo.map((details) => (
                    <div styleName={"styles.cardWrapperLast"} key={details?.cityCode}>
                        <OurLocationCard heading={details?.displayName} subHeading={details?.address} redirectLink={details?.mapLink} />
                    </div>)
                )}
                <CheckCarsFromAllStates heading="CARS24 delivers all around Australia" subHeading="You can check cars from all around Australia. We transport the car to your location with transfer fee." />
            </div>
        </Modal>
    );
};

OurLocationModal.propTypes = {
    handleModalClose: PropTypes.func
};
export default OurLocationModal;
